import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Audiobook App Template | Audiobook App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/audiobook-mobile-app/"
    metaDescription="Working on your own audiobook design project? Our app mockup template is here to help you bring your idea to life. Sign up for Uizard Pro today!"
    description="
    h2:ReadAudio, our audiobook app design template for mobile devices
    <br/>
    Working on your own audiobook design project? ReadAudio, our new <a:https://uizard.io/mockups/>UI mockup</a> template is here to drive your project forward. ReadAudio comes packed with everything you could need for your app design project. To get started, simply sign up for Uizard Pro, select the template in the Uizard app, and start bringing your ideas to life.
    <br/>
    h3:ReadAudio is a real page-turner with limitless customization
    <br/>
    Uizard provides you with limitless customization options meaning you can make any of our <a:https://uizard.io/templates/mobile-app-templates/>app design templates</a> your own. Drop in new components, add in new screens, and map new user journeys; the possibilities are endless.
    <br/>
    h3:Tell your product story alone or with your team with Uizard's smart collab features
    <br/>
    To share your brand-new audiobook app design, you can add teammates and external stakeholders to your project with the click of a button. Share for feedback or share for real-time collaboration, the choice is yours! Uizard empowers you to prototype and collaborate faster than ever before!
    "
    pages={[
      "A streamlined and refined user landing page with sign-in/sign up CTAs",
      "Genre selection screen mockup alongside other onboarding screens",
      "Book listing screen showcasing new and popular books",
      "An audiobook player screen showcasing how users will listen to books",
    ]}
    projectCode="VRzVzqgn0pi4opZlv7YL"
    img1={data.image1.childImageSharp}
    img1alt="audiobook mobile app cover screen"
    img2={data.image2.childImageSharp}
    img2alt="audiobook mobile app player screen"
    img3={data.image3.childImageSharp}
    img3alt="audiobook mobile app homepage screen"
    img4={data.image4.childImageSharp}
    img4alt="audiobook mobile app onboarding"
    img5={data.image5.childImageSharp}
    img5alt="audiobook mobile app cover summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/audiobook-mobile-app/audiobook-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/audiobook-mobile-app/audiobook-mobile-player.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/audiobook-mobile-app/audiobook-mobile-homepage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/audiobook-mobile-app/audiobook-mobile-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/audiobook-mobile-app/audiobook-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
